import React, { useState, useEffect } from "react"
import {
  Card,
  CardBody,
  Col,
  Row,
  DropdownMenu,
  DropdownItem,
  DropdownToggle,
  UncontrolledDropdown,
  Modal,
  ModalBody,
  ModalHeader,
  Form,
  Label,
  Input,
  FormFeedback,
  UncontrolledTooltip,
  CardHeader,
  Badge
} from "reactstrap"

import logo from '../../assets/images/logo-sm.png'

import avatar1 from "../../assets/images/users/avatar-1.jpg";



//redux
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd"
import { Link } from "react-router-dom"
import SimpleBar from "simplebar-react"
import Spinners from "components/Common/Spinner"


// import { useSelector, useDispatch } from "react-redux"
// import { createSelector } from "reselect"
// import moment from "moment"
// import { ToastContainer } from "react-toastify"
import { AddTeamMember, headData, tasklist } from "common/data"



//Import Breadcrumb
interface CardData {
  id?: string;
  kanId?: string,
  title?: string,
  cardId?: string;
  botId?: any,
  text?: string;
  badge1?: any[];
  userImages?: any[];
  badgeColor?: string;
  eye?: boolean;
  que?: boolean;
  clip?: boolean;
}


interface KanbanColumn {
  id: string;
  name: string;
  badge?: number;
  color?: string;
  cards?: any;
}

const TasksKanban = () => {

  const [cards, setCards] = useState<any>(tasklist)

  const handleDragEnd = (result: any) => {
    if (!result.destination) return // If dropped outside a valid drop area, do nothing

    const { source, destination } = result
    // Reorder cards within the same card line
    if (source.droppableId === destination.droppableId) {
      const line = cards.find((line: any) => line.id === source.droppableId)
      const reorderedCards = Array.from(line.cards)
      const [movedCard] = reorderedCards.splice(source.index, 1)
      reorderedCards.splice(destination.index, 0, movedCard)

      const updatedLines = cards.map((line: any) => {
        if (line.id === source.droppableId) {
          return { ...line, cards: reorderedCards }
        }
        return line
      })

      setCards(updatedLines)
    } else {
      // Move card between different card lines
      const sourceLine = cards.find((line: any) => line.id === source.droppableId)
      const destinationLine = cards.find(
        (line: any) => line.id === destination.droppableId
      )
      const sourceCards = Array.from(sourceLine.cards)
      const destinationCards = Array.from(destinationLine.cards)
      const [movedCard] = sourceCards.splice(source.index, 1)
      destinationCards.splice(destination.index, 0, movedCard)

      const updatedLines = cards.map((line: any) => {
        if (line.id === source.droppableId) {
          return { ...line, cards: sourceCards }
        } else if (line.id === destination.droppableId) {
          return { ...line, cards: destinationCards }
        }
        return line
      })

      setCards(updatedLines)
    }
  }




  return (
    <React.Fragment>

      <Card>
        <CardBody>
          <div className="border-bottom pb-4 mb-4">
            <Row >
              <Col sm='6'>
                <div className="d-flex">
                  <div className="avatar flex-shrink-0 me-3">
                    <div className="avatar-title bg-light rounded-circle">
                      <img src={logo} alt="" height={28} />
                    </div>
                  </div>
                  <div className="flex-grow-1">
                    <h5 className="font-size-16 mb-1">
                      Dashonic Admin Dashboard
                    </h5>
                    <p className="text-muted mb-0">
                      Lorem ipsum dolor sit amet adipiscing elit
                    </p>
                  </div>
                </div>
              </Col>

              <Col sm='6'>
                <div className="mt-4 mt-sm-0">
                  <div className="avatar-group justify-content-sm-end">

                    <div className="avatar-group-item">
                      <Link to="#" id="member-1" >
                        <div className="avatar-sm">
                          <span className={"avatar-title rounded-circle bg-primary text-white font-size-16"} >
                            E
                          </span>
                          <UncontrolledTooltip placement="top" target="member-1">
                            Emily Surface
                          </UncontrolledTooltip>
                        </div>
                      </Link>
                    </div>


                    <div className="avatar-group-item" >
                      <Link to="#" className="d-inline-block" id="member-2" >
                        <img
                          src={avatar1}
                          className="rounded-circle avatar-sm"
                          alt=""
                        />
                        <UncontrolledTooltip placement="top" target="member-2" >
                          James Scott
                        </UncontrolledTooltip>
                      </Link>
                    </div>


                    <div className="avatar-group-item">
                      <Link to="#" id="member-3" >
                        <div className="avatar-sm">
                          <span className="avatar-title rounded-circle bg-info text-white font-size-16" >
                            L
                          </span>
                          <UncontrolledTooltip placement="top" target="member-3">
                            Lynn Hackett
                          </UncontrolledTooltip>
                        </div>
                      </Link>
                    </div>

                    <div className="avatar-group-item">
                      <Link
                        to="#" id="member-4"
                        className="d-block"
                      >
                        <div className="avatar-sm">
                          <div className="avatar-title rounded-circle bg-light text-primary">
                            <i className="mdi mdi-plus fs-5" />
                          </div>
                        </div>
                        <UncontrolledTooltip placement="top" target="member-4">
                          Add New
                        </UncontrolledTooltip>
                      </Link>
                    </div>

                  </div>

                </div>
              </Col>
            </Row>
          </div>

          <div className="task-board ">
            <Row >
              <DragDropContext onDragEnd={handleDragEnd}>
                {(cards || []).map((line: KanbanColumn) => {
                  return (
                    // header line
                    <Col sm='4' key={line.id}>
                      <div className="tasks-list" >
                        <Card className=" bg-light shadow-none h-100">

                          <CardHeader className=" bg-transparent border-bottom d-flex align-items-start">
                            <div className="flex-grow-1">
                              <h4 className="card-title mb-0">
                                Todo <span className="ml-1 text-muted">(03)</span>
                              </h4>
                            </div>
                            <div className="flex-shrink-0">
                              <UncontrolledDropdown >
                                <DropdownToggle
                                  href="#"
                                  tag='a'
                                  className="arrow-none font-size-16"
                                  data-bs-toggle="dropdown"
                                  aria-expanded="false"
                                >
                                  <i className="uil uil-ellipsis-h text-muted" />
                                </DropdownToggle>
                                <DropdownMenu direction="end" >
                                  <DropdownItem to="#">
                                    Edit
                                  </DropdownItem>
                                  <DropdownItem to="#">
                                    Delete
                                  </DropdownItem>
                                </DropdownMenu>
                              </UncontrolledDropdown>{" "}

                            </div>
                          </CardHeader>

                          <div>
                            <div className="text-center p-3">
                              <Link
                                to="#"
                                className="btn btn-primary btn-soft w-100"
                              >
                                <i className="mdi mdi-plus mr-1" /> Add New
                              </Link>
                            </div>

                            <div data-simplebar="" className="tasklist-content p-3 z-1">
                              <div id="todo-task" className="tasks">
                                {/* data */}
                                <SimpleBar className="tasks-wrapper px-3 mx-n3">
                                  <div id="unassigned-task" className={line.cards === "object" ? "tasks" : "tasks noTask"}>
                                    <Droppable droppableId={line.id}>
                                      {(provided: any) => (
                                        <div
                                          ref={provided.innerRef}
                                          {...provided.droppableProps}
                                        >
                                          {line.cards.map((card: any, index: any) => {
                                            return (
                                              <Draggable
                                                key={card.id}
                                                draggableId={card.id}
                                                index={index}
                                              >
                                                {(provided: any) => (
                                                  <div
                                                    ref={provided.innerRef}
                                                    {...provided.draggableProps}
                                                    {...provided.dragHandleProps}
                                                    id={line.name + "-task"}
                                                    data-simplebar=""
                                                  >

                                                      <Card className="task-box z-50" >
                                                        <CardBody>
                                                          <div className="d-flex align-items-start mb-2">
                                                            <div className="flex-grow-1">
                                                              <div className="form-check">
                                                                <input
                                                                  className="form-check-input"
                                                                  type="checkbox"
                                                                  defaultValue=""
                                                                  id="task-todoCheck1"
                                                                />
                                                                <label
                                                                  className="form-check-label text-primary"
                                                                  htmlFor="task-todoCheck1"
                                                                >
                                                                  {card.idName}
                                                                </label>
                                                              </div>
                                                            </div>
                                                            <div className="flex-shrink-0 ms-2">
                                                              <UncontrolledDropdown >
                                                                <DropdownToggle
                                                                  href="#"
                                                                  tag='a'
                                                                  className="arrow-none font-size-16"
                                                                  data-bs-toggle="dropdown"
                                                                  aria-expanded="false"
                                                                // aria-haspopup="true"
                                                                >
                                                                  <i className="mdi mdi-dots-horizontal" />
                                                                </DropdownToggle>
                                                                <DropdownMenu dir='end'>
                                                                  <DropdownItem href="#">
                                                                    View
                                                                  </DropdownItem>
                                                                  <DropdownItem href="#">
                                                                    Edit
                                                                  </DropdownItem>
                                                                  <DropdownItem href="#">
                                                                    Remove
                                                                  </DropdownItem>
                                                                </DropdownMenu>
                                                              </UncontrolledDropdown>
                                                            </div>
                                                          </div>

                                                          <h5 className="font-size-14 mb-1">{card.title}</h5>
                                                          <p className="text-muted text-truncate">
                                                            {card.text}
                                                          </p>

                                                          {/* avtar list  */}
                                                          <div className="avatar-group">

                                                            {
                                                              card.userImages
                                                                ? card.userImages.map((data: any, index: any) => {
                                                                  return (
                                                                    <div className="avatar-group-item" key={`${index}-avtar`}>

                                                                      {
                                                                        data.img
                                                                          ? <Link to="#" className="d-inline-block" id={`member-${data.id}`} >
                                                                            <img
                                                                              src={data.img}
                                                                              className="rounded-circle avatar-sm"
                                                                              alt=""
                                                                            />
                                                                            <UncontrolledTooltip placement="top" target={`member-${data.id}`} >
                                                                             {data.avatarName}
                                                                            </UncontrolledTooltip>
                                                                          </Link>
                                                                          : <Link to="#" id={`member-${data.id}`} >
                                                                            <div className="avatar-sm">
                                                                              <span className={`avatar-title rounded-circle ${data.bgColor} text-white font-size-16`} >
                                                                                {data.text}
                                                                              </span>
                                                                              <UncontrolledTooltip placement="top" target={`member-${data.id}`}>
                                                                               {data.avatarName}
                                                                              </UncontrolledTooltip>
                                                                            </div>
                                                                          </Link>

                                                                      }
                                                                    </div>
                                                                  )
                                                                })
                                                                : ''
                                                            }
                                                          </div>

                                                        </CardBody>
                                                        <div className="card-footer bg-transparent border-top d-flex">
                                                          <div className="flex-grow-1">
                                                            <div className="font-size-13 text-muted">
                                                              {card.date}
                                                            </div>
                                                          </div>
                                                          <div className="flex-shrink-0 ms-2">
                                                            <Badge color="link"
                                                              pill className={`${card.badgeColor} font-size-12`}>
                                                              {card.badgetitle}
                                                            </Badge>
                                                          </div>
                                                        </div>

                                                      </Card>
                                                  </div>
                                                )}
                                              </Draggable>
                                            )
                                          })}
                                        </div>
                                      )}
                                    </Droppable>
                                  </div>
                                </SimpleBar>
                              </div>
                            </div>


                          </div>
                        </Card>
                      </div>
                    </Col>
                  )
                })}
              </DragDropContext>
            </Row>
          </div>
        </CardBody>
      </Card>

    </React.Fragment >
  )
}

export default TasksKanban
