import avatar5 from "assets/images/users/avatar-5.jpg"
import avatar3 from "assets/images/users/avatar-3.jpg"
import avatar10 from "assets/images/users/avatar-10.jpg"
import avatar8 from "assets/images/users/avatar-8.jpg"
import avatar2 from "assets/images/users/avatar-2.jpg"
import avatar1 from "assets/images/users/avatar-1.jpg"
import avatar4 from "assets/images/users/avatar-4.jpg"
import avatar6 from "assets/images/users/avatar-6.jpg"
import avatar7 from "assets/images/users/avatar-7.jpg"
import avatar9 from "assets/images/users/avatar-9.jpg"
import imge7 from "assets/images/small/img-7.jpg"
import imge4 from "assets/images/small/img-4.jpg"
const headData = [
  {
    id: 1,
    name: "Nancy",
    picture: avatar5,
  },
  {
    id: 2,
    name: "Frank",
    picture: avatar3,
  },
  {
    id: 3,
    name: "Tonya",
    picture: avatar10,
  },
  {
    id: 4,
    name: "Thomas",
    picture: avatar8,
  },
  {
    id: 5,
    name: "Herbert",
    picture: avatar2,
  },
];


const tasklist = [
  {
    id: "1",
    name: "Todo",
    color: "success",
    cards: [
      {
        id: "2",
        idName: 'DS-045',
        title: "Dashboard UI",
        text: "Sed ut perspiciatis unde omnis iste.",
        userImages: [{ id: 1, text: 'E', bgColor: 'bg-primary', avatarName: 'Emily Surface' }],
        userimagesColor: "success",        
        badgetitle: "Open",
        badgeColor: 'badge-primary-subtle',
        date: "09 Mar, 2020",        
      },
      {
        id: "3",
        idName: 'DS-046',
        title: "Calendar App Page",
        text: "Neque porro quisquam est.",
        userImages: [{ id: 2, img: avatar2, avatarName: 'James Scott' }, { id: 3, text: 'E', bgColor: 'bg-info', avatarName: 'Emily Surface' }],
        userimagesColor: "success",        
        badgetitle: "Open",
        badgeColor: 'badge-primary-subtle',
        date: "08 Mar, 2020",        
      },
      {
        id: "4",
        idName: 'DS-047',
        title: "Authentication Page Design",
        text: "In enim justo rhoncus ut.",
        userImages: [{ id: 4, text: 'E', bgColor: 'bg-danger', avatarName: 'Emily Surface' }],
        userimagesColor: "success",        
        badgetitle: "Open",
        badgeColor: 'badge-primary-subtle',
        date: "08 Mar, 2020",        
      },
    ],
  },
  {
    id: "2",
    name: "In Progress ",
    badge: 1,
    color: "secondary",
    cards: [
      {
        id: "5",
        idName: 'DS-044',
        title: "Component Pages",
        text: "Donec quam felis ultricies nec.",
        userImages: [{ id: 5, img: avatar2, avatarName: 'James Scott' }],
        userimagesColor: "success",        
        badgetitle: "Inprogress",
        badgeColor: 'badge-warning-subtle',
        date: "08 Mar, 2020",        
      },     
    ],
  },
  {
    id: "3",
    name: "Completed",
    badge: 2,
    color: "warning",
    cards: [
      {
        id: "8",
        idName: 'DS-041',
        title: "Admin Layout Design",
        text: "At vero eos et accusamus et .",
        userImages: [{ id: 6, img: avatar2, avatarName: 'James Scott' }, { id: 7, text: 'L', bgColor: 'bg-info', avatarName: 'liver Sharp' }],
        userimagesColor: "success",        
        badgetitle: "Completed",
        badgeColor: 'badge-success-subtle',
        date: "06 Mar, 2020",
        eye: "24",
        que: "10",
        clip: "10",
        botpro: "55%",
        botprocolor: "warning"
      },
      {
        id: "9",
        idName: 'DS-042',
        title: "Brand Logo Design ",
        text: "Nemo enim ipsam voluptatem.",
        userImages: [{ id: 8, text: 'E', bgColor: 'bg-primary', avatarName: 'Emily Surface' }],
        userimagesColor: "success",        
        badgetitle: "Completed",
        badgeColor: 'badge-success-subtle',
        date: "07 Mar, 2020",
        eye: "64",
        que: "35",
        clip: "23"
      },
    ],
  },
];
const AddTeamMember = [
  { id: 1, img: avatar1, name: 'Albert Rodarte' },
  { id: 2, img: avatar2, name: 'Hannah Glover' },
  { id: 3, img: avatar3, name: 'Adrian Rodarte' },
  { id: 4, img: avatar4, name: 'Frank Hamilton' },
  { id: 5, img: avatar5, name: 'Justin Howard' },
  { id: 6, img: avatar6, name: 'Michael Lawrence' },
  { id: 7, img: avatar7, name: 'Oliver Sharp' },
  { id: 8, img: avatar8, name: 'Richard Simpson' }
]
export { headData, tasklist, AddTeamMember }