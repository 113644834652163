import React from "react";
import ReactApexChart from "react-apexcharts";

const Basic = () => {
  const series = [{
    data: [380, 430, 450, 475, 550, 584, 780, 1100, 1220, 1365]
  }];

  const options = {
    chart: {
      toolbar: {
        show: !1,
      }
    },
    plotOptions: {
      bar: {
        horizontal: !0,
      }
    },
    dataLabels: {
      enabled: !1
    },
    colors: ["#51d28c"],
    grid: {
      borderColor: "#f1f1f1",
    },
    xaxis: {
      categories: ["South Korea", "Canada", "United Kingdom", "Netherlands", "Italy", "France", "Japan", "United States", "China", "Germany"],
    }
  };

  return (
    <React.Fragment>
      <ReactApexChart
        options={options}
        series={series}
        type="bar"
        height={350}
      />
    </React.Fragment>
  );
};

export default Basic;
