import React from 'react'
//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";

import TasksKanban from './MainPage'


import {
    Container,
} from "reactstrap";

const KanbanBord = () => {
    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>

                    <Breadcrumbs title="Apps" breadcrumbItem="Kanban Board" />
                    <TasksKanban />


                </Container>
            </div>
        </React.Fragment>
    )
}

export default KanbanBord
