import React, { Fragment, useEffect, useState } from "react";
import PropTypes from "prop-types";
import {
  useTable,
  useGlobalFilter,
  useAsyncDebounce,
  useSortBy,
  useFilters,
  useExpanded,
  usePagination,
} from "react-table";

import {
  Column,
  Table as ReactTable,
  ColumnFiltersState,
  FilterFn,
  useReactTable,
  getCoreRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  flexRender
} from '@tanstack/react-table';

import { rankItem } from '@tanstack/match-sorter-utils';

import {
  Table, Row, Col, Button,
  PaginationItem,
  PaginationLink
} from "reactstrap";
import { Filter } from "../Common/Filter";
import { Link } from "react-router-dom";

interface GlobalFilterProps {
  preGlobalFilteredRows?: any;
  globalFilter?: any;
  setGlobalFilter?: any;
  isJobListGlobalFilter?: any;
}
const DebouncedInput = ({
  value: initialValue,
  onChange,
  debounce = 500,
  ...props
}: {
  value: string | number;
  onChange: (value: string | number) => void;
  debounce?: number;
} & Omit<React.InputHTMLAttributes<HTMLInputElement>, 'onChange'>) => {
  const [value, setValue] = useState(initialValue);

  useEffect(() => {
    setValue(initialValue);
  }, [initialValue]);

  useEffect(() => {
    const timeout = setTimeout(() => {
      onChange(value);
    }, debounce);

    return () => clearTimeout(timeout);
  }, [debounce, onChange, value]);

  return (
    <React.Fragment>
      <Col md='7' sm='7'>
        <div className="search-box me-xxl-2 my-3 my-xxl-0 d-inline-block">
          <div className="position-relative">
            <label htmlFor="search-bar-0" className="search-label">
              <span id="search-bar-0-label" className="sr-only">
                Search this table
              </span>

            </label>
              <input {...props} value={value} placeholder="Search this table..." onChange={e => setValue(e.target.value)} />
          </div>
        </div>
      </Col>
    </React.Fragment>
  );
};

interface TableContainerProps {
  columns?: any;
  data?: any;
  isGlobalFilter?: any;
  isJobListGlobalFilter?: any;
  isAddOptions?: any;
  isAddUserList?: any;
  handleUserClick?: any;
  handleOrderClicks?: any;
  handleCustomerClick?: any;
  isAddCustList?: any;
  customPageSize?: any;
  className?: any;
  customPageSizeOptions?: any;
  isAddInvoiceList?: any;
  handleInvoiceClick?: any;
  PaginationClassName?: string;
  isBordered?: boolean;
  SearchPlaceholder?: string;
  isPagination: boolean;
}

const TableContainer = ({
  columns,
  data,
  isGlobalFilter,
  isJobListGlobalFilter,
  isAddUserList,
  isBordered,
  handleUserClick,
  PaginationClassName,
  customPageSize,
  className,
  isPagination,
  isAddInvoiceList,
  handleInvoiceClick,
  SearchPlaceholder
}: TableContainerProps) => {

  const [columnFilters, setColumnFilters] = useState<ColumnFiltersState>([]);
  const [globalFilter, setGlobalFilter] = useState('');

  const fuzzyFilter: FilterFn<any> = (row, columnId, value, addMeta) => {
    const itemRank = rankItem(row.getValue(columnId), value);
    addMeta({
      itemRank
    });
    return itemRank.passed;
  };



  const table = useReactTable({
    columns,
    data,
    filterFns: {
      fuzzy: fuzzyFilter,
    },
    state: {
      columnFilters,
      globalFilter,
    },
    onColumnFiltersChange: setColumnFilters,
    onGlobalFilterChange: setGlobalFilter,
    globalFilterFn: fuzzyFilter,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getSortedRowModel: getSortedRowModel()
  });

  const {
    getHeaderGroups,
    getRowModel,
    getCanPreviousPage,
    getCanNextPage,
    getPageOptions,
    setPageIndex,
    nextPage,
    previousPage,
    setPageSize,
    getState
  } = table

  useEffect(() => {
    Number(customPageSize) && setPageSize(Number(customPageSize));
  }, [customPageSize, setPageSize]);



  return (
    <Fragment>
      <Row className="mb-2">
        {isGlobalFilter && (
          <DebouncedInput
            value={globalFilter ?? ''}
            onChange={value => setGlobalFilter(String(value))}
            className="p-2 font-lg shadow border border-block"
            placeholder={SearchPlaceholder}
          />
        )}
        {isAddInvoiceList && (
          <Col sm="7">
            <div className="text-sm-end">
              <Button
                onClick={handleInvoiceClick}
                type="button"
                className="btn btn-light waves-effect waves-light"
              >
                <i className="bx bx-plus me-1"></i> Add Invoice
              </Button>
            </div>
          </Col>
        )}
        {isAddUserList && (
          <Col sm="5">
            <div className="text-sm-end">
              <Button
                type="button"
                color="primary"
                className="btn mb-2 me-2"
                onClick={handleUserClick}
              >
                <i className="mdi mdi-plus-circle-outline me-1" />
                Create New User
              </Button>
            </div>
          </Col>
        )}
      </Row>

      <div className="table-responsive react-table">
        <Table bordered={isBordered} hover className={className}>
          <thead className="table-light table-nowrap">
            {getHeaderGroups().map((headerGroup: any) => (
              <tr key={headerGroup.id}>
                {headerGroup.headers.map((header: any) => {
                  return (
                    <th key={header.id} colSpan={header.colSpan}
                      {...{
                        className: header.column.getCanSort()
                          ? 'cursor-pointer select-none sort'
                          : '',
                        onClick: header.column.getToggleSortingHandler(),
                      }}>
                      {header.isPlaceholder ? null : (
                        <React.Fragment>
                          {flexRender(
                            header.column.columnDef.header,
                            header.getContext()
                          )}
                          {{
                            asc: ' 🔽',
                            desc: ' 🔼',
                          }

                          [header.column.getIsSorted() as string] ?? null}
                          {header.column.getCanFilter() ? (
                            <div>

                              <Filter column={header.column} table={table} />
                            </div>
                          ) : null}
                        </React.Fragment>
                      )}
                    </th>
                  );
                })}
              </tr>
            ))}
          </thead>

          <tbody >
            {getRowModel().rows.map(row => {
              return (
                <tr key={row.id}>
                  {row.getVisibleCells().map(cell => {
                    return (
                      <td key={cell.id}>
                        {flexRender(
                          cell.column.columnDef.cell,
                          cell.getContext()
                        )}
                      </td>
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
        </Table>
      </div>



      {
        isPagination && (
          <Row className={PaginationClassName}>
            <div className="col-sm">
              <div className="text-muted">Showing <span className="fw-semibold">{getState().pagination.pageSize}</span> of <span className="fw-semibold">{data.length}</span> Results</div>
            </div>
            <div className="col-sm-auto mt-3 mt-sm-0">
              <div className="pagination-wrap hstack gap-2 justify-content-center">
                <Button variant="link" className="page-item pagination-rounded pagination-prev" disabled={!getCanPreviousPage()} onClick={previousPage}>
                  <i className="mdi mdi-chevron-left align-middle"></i>
                </Button>
                <ul className="pagination pagination-rounded mb-sm-0">
                  {getPageOptions().map((item: any, key: number) => (
                    <React.Fragment key={key}>
                      <PaginationItem className={getState().pagination.pageIndex === item ? "active" : ""}>
                        <PaginationLink to="#" className="page" onClick={() => setPageIndex(item)}>{item + 1}</PaginationLink>
                      </PaginationItem>
                    </React.Fragment>
                  ))}
                </ul>
                <Button variant="link" className=" pagination-rounded pagination-next" disabled={!getCanNextPage()} onClick={nextPage}>
                  <i className="mdi mdi-chevron-right"></i>
                </Button>
              </div>
            </div>
          </Row>
        )
      }
    </Fragment>
  );
};

TableContainer.propTypes = {
  preGlobalFilteredRows: PropTypes.any,
};

export default TableContainer;